import React, { useState, useEffect } from "react";
import styles from "./App.module.css";
import Upload from "./Upload";
import Files from "./Files";
import logo_lyf from "./assets/logo_lyf.png";

const Loading = () => <h3 className={styles.Loading}>Chargement…</h3>;

const testToken = token => {
  return fetch(`${process.env.REACT_APP_API_URI}/files`, {
    headers: { Authorization: `Bearer ${token}` }
  }).then(response => (response.ok ? Promise.resolve() : Promise.reject()));
};

const Login = ({ onToken }) => {
  const [value, setValue] = useState("");

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onToken(value);
      }}
      className={styles.Login}
    >
      <h1 className={styles.Title}>Lyf QA</h1>
      <input
        type="password"
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder="Mot de passe"
        className={styles.Input}
      />
      <input type="submit" value="Valider" className={styles.Submit} />
    </form>
  );
};

const App = () => {
  const [version, setVersion] = useState(0);

  return (
    <div>
      <div className={styles.Title}>
        <img src={logo_lyf} alt="Logo Lyf" />
        <h1>Lyf QA</h1>
      </div>
      <Files version={version} />
      <Upload onUploaded={() => setVersion(v => v + 1)} />
    </div>
  );
};

export default () => {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("auth");
    if (token) {
      setIsLoading(true);
      testToken(token)
        .then(() => {
          setIsLogged(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsLogged(false);
        });
    }
  }, []);

  const checkLogin = token => {
    setIsLoading(true);
    testToken(token)
      .then(() => {
        localStorage.setItem("auth", token);
        setIsLogged(true);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        alert("Mot de passe incorrect");
      });
  };

  if (isLogged) {
    return <App />;
  }

  return (
    <div className={styles.LoginContainer}>
      {isLoading ? <Loading /> : <Login onToken={checkLogin} />}
    </div>
  );
};
