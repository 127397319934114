import React, { useState, useEffect } from "react";
import { fetchApi } from "./api";
import styles from "./Files.module.css";

const NoFile = () => (
  <div className={styles.NoFile}>
    Aucune version de l’application n’est pour le moment disponible.
  </div>
);
const Loading = () => <div className={styles.NoFile}>Chargement des apps…</div>;

const EnvPicker = ({ onChange, active }) => {
  return (
    <div className={styles.EnvPicker}>
      <button
        className={active === "production" && styles.EnvActive}
        onClick={() => onChange("production")}
      >
        PRODUCTION
      </button>
      <button
        className={active === "recette" && styles.EnvActive}
        onClick={() => onChange("recette")}
      >
        RECETTE
      </button>
    </div>
  );
};

const File = ({ name, bundleVersion, id }) => {
  return (
    <a
      className={styles.FileContainer}
      title="Télécharger cette app"
      href={`itms-services://?action=download-manifest&url=https://lyfqa.neos.app/api/files/${id}.plist`}
    >
      <div className={styles.FileContainerBar} />
      <div className={styles.Content}>
        <h4>{name.replace("LyfPay", "")}</h4>
        <h5>{bundleVersion}</h5>
      </div>
    </a>
  );
};

const Apps = ({ versions }) => {
  const sortedVersions = Object.keys(versions).sort((va, vb) => {
    const rawVersionA = va.split("RC")[0];
    const rawVersionB = vb.split("RC")[0];
    if (rawVersionA === rawVersionB) {
      if (!vb.includes("RC")) {
        return 1;
      } else if (!va.includes("RC")) {
        return -1;
      } else {
        return rawVersionB.localeCompare(rawVersionA);
      }
    } else {
      return rawVersionB.localeCompare(rawVersionA);
    }
  });

  return (
    <>
      {sortedVersions.map(version => {
        const apps = versions[version];

        return (
          <div key={version}>
            <h3 className={styles.Version}>{version}</h3>
            {apps.map(app => (
              <File key={app.id} {...app} />
            ))}
          </div>
        );
      })}
    </>
  );
};

const getFiles = async () => {
  const data = await fetchApi("/files");
  const files = await data.json();

  return files;
};

const groupApps = files => {
  const apps = { production: {}, recette: {} };

  for (const file of files) {
    const env = file.name.startsWith("LyfPayRCT") ? "recette" : "production";
    const version = file.bundleVersion.split("_")[0];
    if (!apps[env][version]) {
      apps[env][version] = [];
    }
    apps[env][version].push(file);
  }

  return apps;
};

export default ({ version }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [env, setEnv] = useState("recette");

  useEffect(() => {
    getFiles()
      .then(setFiles)
      .then(() => setLoading(false));
  }, [version]);

  const apps = groupApps(files);

  return (
    <div>
      <EnvPicker active={env} onChange={setEnv} />
      <div className={styles.Container}>
        {!loading && files.length === 0 && <NoFile />}
        {loading && files.length === 0 && <Loading />}
        <Apps versions={apps[env]} />
      </div>
    </div>
  );
};
