import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./Upload.module.css";
import axios from "axios";
import { fetchApi } from "./api";

export default ({ onUploaded }) => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    let nbUploaded = 0;
    if (acceptedFiles.length > 0) {
      setUploading(true);
    }

    acceptedFiles.forEach(async file => {
      const urlData = await fetchApi("/files", {
        method: "POST",
        body: JSON.stringify({ filename: file.name }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      const data = await urlData.json();

      let previousLoaded = 0;
      await axios({
        url: data.url,
        method: "PUT",
        data: file,
        headers: { "content-type": " " },
        onUploadProgress: progressEvent => {
          if (progressEvent.total && progressEvent.loaded) {
            const newLoaded = progressEvent.loaded - previousLoaded;
            setProgress(
              p =>
                p +
                (0.9 * newLoaded) / progressEvent.total / acceptedFiles.length
            );
            previousLoaded = progressEvent.loaded;
          }
        }
      });

      await fetchApi(`/files/${data.filename}/uploaded`, {
        method: "POST"
      });

      onUploaded();
      nbUploaded += 1;
      if (nbUploaded === acceptedFiles.length) {
        setProgress(0);
        setUploading(false);
      } else {
        setProgress(p => p + 0.1 / acceptedFiles.length);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".ipa, .apk"
  });

  return (
    <div>
      {uploading ? (
        <div className={styles.Dropzone}>
          Envoi en cours : {Math.round(progress * 10000) / 100} %
        </div>
      ) : (
        <div {...getRootProps()} className={styles.Dropzone}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Déposez les fichiers ici</p>
          ) : (
            <p>
              Cliquez pour uploader une nouvelle app ou faîtes glisser les
              fichiers ici
            </p>
          )}
        </div>
      )}
    </div>
  );
};
